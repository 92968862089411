.container {
  display: flex;
  align-self: center;
  margin: 0.3rem 0;
  min-height: 1.8rem;
  transform: translateY(-3rem);
}

.container--hidden {
  opacity: 0;
}

.container--display {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.6s ease-out;
}
