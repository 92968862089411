.link {
  background-color: unset;
  outline: none;
  border: none;
  font-size: 2rem;
  color: var(--interactive-text);
  margin: 0 1.4rem;
}

.link:hover {
  cursor: pointer;
  color: var(--interactive-text--light);
}
