.container {
  position: absolute;
  right: 1.6rem;
  top: 1.6rem;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
  background-color: transparent;
  font-size: 2.4rem;
  color: #787878;
}

.button:hover {
  cursor: pointer;
  color: #515151;
}

.button__description {
  user-select: none;
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 0.2rem;
}
