.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  overflow: hidden;
  padding: 1rem 0;
  opacity: 0;
}

.container.init {
  opacity: 1;
  transition: opacity .4s ease-in;
}

.title {
  font-size: 1.8rem;
  white-space: pre-wrap;
  text-align: center;
  padding: 0 0.4rem;
  min-width: 100%;
  min-height: 4.8rem;
}

/* TODO: Need a better way of making the image not move when title goes on second line */
@media screen and (max-width: 655px) {
  .title {
    min-width: auto;
    width: 90%;
  }
}

@media screen and (max-width: 360px) { 
  .title {
    font-size: 1.5rem;
  }
}

.title-name {
  font-weight: bold;
}

.picture {
  width: var(--bio-image-size);
  height: var(--bio-image-size);
  border-radius: 50rem;
  box-shadow: 0 0 0.6rem var(--drop-shadow);
  margin-bottom: 1rem;
  background-image: linear-gradient(#ececec, #f3f3f3);
}
