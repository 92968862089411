body {
  --main-bg-color: #f7f7f7;

  --main-bg-color--start: #f7f7f785;
  --main-bg-color--end: #e5e5e5ba;

  --primary-color: #0b526c;

  --interactive-text: #0066da;
  --interactive-text--light: #018fee;

  --drop-shadow: #5b5e5f;

  --bio-image-size: 28rem;
}
